.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.catlink.active {
  color: white !important;
  background-color: #5A6268 !important;
} 

.catlink {
  color: #5A6268 !important;
  
} 
#root {
  padding-top: 55px;
}

table{
  width: 100%;
  border-collapse:collapse;
  border-spacing:0
  
  }
  table, td, th {
  border: 1px solid #595959;

  }
  td, th {
  padding: 3px;
  width: 30px;
  height: 35px;
  text-align: center;
  }
  th {
  background-color: #7accee;
  }